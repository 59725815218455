$dimensions: 16 24 32 48 64 96 128 !default;

.image {
  display: block;
  position: relative;

  img {
    display: block;
    height: auto;
    width: 100%;

    &.is-rounded {
      border-radius: $radius-rounded;
    }
  }

  // Ratio
  &.is-square,
  &.is-1by1,
  &.is-4by3,
  &.is-3by2,
  &.is-16by9,
  &.is-2by1 {
    img {
      @include overlay;

      height: 100%;
      width: 100%;
    }
  }

  &.is-square,
  &.is-1by1 {
    padding-top: 100%;
  }

  &.is-4by3 {
    padding-top: 75%;
  }

  &.is-3by2 {
    padding-top: 66.6666%;
  }

  &.is-16by9 {
    padding-top: 56.25%;
  }

  &.is-2by1 {
    padding-top: 50%;
  }

  // Sizes
  @each $dimension in $dimensions {
    &.is-#{$dimension}x#{$dimension} {
      height: $dimension * 1px;
      width: $dimension * 1px;
    }
  }
}
