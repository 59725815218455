$breadcrumb-item-color: $link !default;
$breadcrumb-item-hover-color: $link-hover !default;
$breadcrumb-item-active-color: $text-strong !default;

$breadcrumb-item-separator-color: $text !default;

.breadcrumb {
  @include block;
  @include unselectable;

  align-items: stretch;
  display: flex;
  font-size: $size-normal;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;

  a {
    align-items: center;
    color: $breadcrumb-item-color;
    display: flex;
    justify-content: center;
    padding: 0.5em 0.75em;

    &:hover {
      color: $breadcrumb-item-hover-color;
    }
  }

  li {
    align-items: center;
    display: flex;

    &:first-child a {
      padding-left: 0;
    }

    &.is-active {
      a {
        color: $breadcrumb-item-active-color;
        cursor: default;
        pointer-events: none;
      }
    }

    & + li::before {
      color: $breadcrumb-item-separator-color;
      content: "/";
    }
  }

  ul, ol {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;
  }

  .icon {
    &:first-child {
      margin-right: 0.5em;
    }

    &:last-child {
      margin-left: 0.5em;
    }
  }

  // Alignment
  &.is-centered {
    ol, ul {
      justify-content: center;
    }
  }

  &.is-right {
    ol, ul {
      justify-content: flex-end;
    }
  }

  // Sizes
  &.is-small {
    font-size: $size-small;
  }

  &.is-medium {
    font-size: $size-medium;
  }

  &.is-large {
    font-size: $size-large;
  }

  // Styles
  &.has-arrow-separator {
    li + li::before {
      content: "→";
    }
  }

  &.has-bullet-separator {
    li + li::before {
      content: "•";
    }
  }

  &.has-dot-separator {
    li + li::before {
      content: "·";
    }
  }

  &.has-succeeds-separator {
    li + li::before {
      content: "≻";
    }
  }
}
